import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

/**
 * This hook will save the first result when the value is not undefined or null
 */
export const useSnapshotState = <V>(
  value: V | undefined,
): [V | undefined, Dispatch<SetStateAction<V | undefined>>] => {
  const [valueReference, setValueReference] = useState<V | undefined>(value);

  useEffect(() => {
    if (!valueReference && value !== null && value !== undefined) setValueReference(value);
  }, [valueReference, value]);

  return [valueReference, setValueReference];
};
