import { atom } from "jotai";
import type { KeyedMutator } from "swr";

import type { UserFollowList } from "./types";

type State = {
  data?: UserFollowList;
  lastSync?: number;
  mutate?: KeyedMutator<UserFollowList>;
};

export const userFollowAtom = atom<State>({});
