import { initializeApp } from "@firebase/app";
import type { FirebaseApp } from "@firebase/app";

import { firebase } from "scmp-app/data/configs/firebase";

let app: FirebaseApp;

export const getFirebaseApp = () => {
  if (!app) {
    app = initializeApp({
      apiKey: firebase.apiKey,
      appId: firebase.appId,
      authDomain: firebase.authDomain,
      databaseURL: firebase.databaseURL,
      messagingSenderId: firebase.messagingSenderId,
      projectId: firebase.projectId,
      storageBucket: firebase.storageBucket,
    });
  }
  return app;
};
