import {
  addDays as addD,
  addMonths as addM,
  addMinutes as addMin,
  addSeconds as addSec,
  differenceInHours,
  differenceInMinutes,
  isAfter,
  isEqual,
  isSameDay,
  isValid,
  parseISO,
} from "date-fns";
import { formatInTimeZone as formatInTZ } from "date-fns-tz";
import { toString } from "duration-fns";

import { config } from "scmp-app/data";

import { isNumeric, isString } from "./types";

export function parseDate(value: string | Date | number) {
  if (isString(value)) {
    const parsed = parseISO(value);
    return isValid(parsed) ? parsed : new Date(value);
  } else if (isNumeric(value)) {
    return new Date(value);
  }
  return value;
}

export function durationToISOString(minutes: number, seconds: number) {
  return toString({
    minutes,
    seconds,
  });
}

export function formatInTimeZone(
  value: string | Date | number,
  format: string = config.date.defaultFormat,
  timeZone: string = config.date.defaultTimeZone,
) {
  return formatInTZ(parseDate(value), timeZone, format);
}

export function formatDistanceToNow(value: string | Date | number) {
  const now = Date.now();
  const parsed = parseDate(value);
  const minutes = differenceInMinutes(now, parsed, { roundingMethod: "round" });
  const hours = differenceInHours(now, parsed, { roundingMethod: "round" });
  switch (true) {
    case minutes === 1:
      return "1 minute ago";
    case minutes > 1 && minutes < 60:
      return `${minutes} minutes ago`;
    case hours === 1:
      return `1 hour ago`;
    case hours > 1 && hours <= 6:
      return `${hours} hours ago`;
    default:
      return formatInTimeZone(parsed);
  }
}

export function toISOString(value: string | Date | number) {
  return parseDate(value).toISOString();
}

export function addDays(value: string | Date | number, amount: number) {
  return addD(parseDate(value), amount);
}

export function addMonths(value: string | Date | number, amount: number) {
  return addM(parseDate(value), amount);
}

export function addMinutes(value: string | Date | number, amount: number) {
  return addMin(parseDate(value), amount);
}

export function addSeconds(value: string | Date | number, amount: number) {
  return addSec(parseDate(value), amount);
}

export function isToday(value: string | Date | number) {
  return isSameDay(parseDate(value), new Date());
}

export function isYesterday(value: string | Date | number) {
  return isSameDay(parseDate(value), addDays(new Date(), -1));
}

export function isAfterOrEqual(
  value: string | Date | number,
  compareValue: string | Date | number,
) {
  const dateA = parseDate(value);
  const dateB = parseDate(compareValue);
  return isAfter(dateA, dateB) || isEqual(dateA, dateB);
}
