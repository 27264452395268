/**
 * @generated SignedSource<<df8f7aaa27616adfdbd3f8d513b365b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksTrackCurrentItemBase$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"atomsCurrentArticleAtomBase" | "atomsCurrentNotificationSectionAtomBase" | "atomsCurrentStyleCardCarouselWidgetSectionsAtomBase" | "atomsCurrentTakeoverAdSectionsAtomBase">;
  readonly " $fragmentType": "hooksTrackCurrentItemBase";
};
export type hooksTrackCurrentItemBase$key = {
  readonly " $data"?: hooksTrackCurrentItemBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTrackCurrentItemBase">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksTrackCurrentItemBase"
};

(node as any).hash = "fffdcc13f7b196f43e1bb54b0ba79a71";

export default node;
